<template>
  <div class="saleSampleSampleDaily">
    <h1>指标分解情况</h1>
    <el-form :inline="true">
      <el-form-item label="取样日期">
        <el-date-picker v-model="searchParam.sampleDate" type="date" style="width: 130px;" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border :span-method="handleSpanMethod" height="calc(100vh - 310px)">
      <el-table-column prop="custom" label="客户" show-overflow-tooltip />
      <el-table-column prop="storage" label="仓储" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.storage">{{ scope.row.storage }}</el-text>
          <el-text v-if="!scope.row.storage" style="font-weight: bolder;">合计</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="customNote" label="销售品名" show-overflow-tooltip />
      <el-table-column prop="mixDetailString" label="配比" show-overflow-tooltip />
      <el-table-column prop="truckQuantity" label="车数" show-overflow-tooltip>
        <template #default="scope">
          <el-text :style="{ 'font-weight': scope.row.storage ? 'normal' : 'bolder' }">{{ scope.row.truckQuantity }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="sumSampleQuantity" label="取样数量" show-overflow-tooltip>
        <template #default="scope">
          <el-text :style="{ 'font-weight': scope.row.storage ? 'normal' : 'bolder' }">{{ scope.row.sumSampleQuantity }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="quanliu" label="平均硫" show-overflow-tooltip>
        <template #default="scope">
          <el-text :style="{ 'font-weight': scope.row.storage ? 'normal' : 'bolder' }">{{ scope.row.quanliu }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="diweifareliangKc" label="平均热·大卡" show-overflow-tooltip>
        <template #default="scope">
          <el-text :style="{ 'font-weight': scope.row.storage ? 'normal' : 'bolder' }">{{ scope.row.diweifareliangKc }}</el-text>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'

const data = reactive({
  list: [],
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.sampleDate = new Date()
}
resetSearchParam()

const commitFindList = () => {
  const params = {}
  if (!searchParam.sampleDate) {
    ElMessage.error("请选择日期")
    return
  }
  params.sampleDate = util.parseTime(searchParam.sampleDate, '{y}-{m}-{d}')
  api.get('/backend/saleSample/sampleDaily', { params: params }).then(res => {
    data.list = res.list
  })
}

const handleSpanMethod = ({ rowIndex, columnIndex }) => {
  const columnIndexs = [0, 1] // 合并
  if (!columnIndexs.includes(columnIndex)) {
    return [1, 1]
  }
  const row = data.list[rowIndex]
  if (rowIndex > 0) {
    const lastrow = data.list[rowIndex - 1]
    if (columnIndex == 0 && row.custom == lastrow.custom) {
      return [0, 0]
    }
    if (columnIndex == 1 && row.custom == lastrow.custom && row.storage == lastrow.storage) {
      return [0, 0]
    }
  }
  let span
  for (span = 1; rowIndex + span < data.list.length; span++) {
    const nextrow = data.list[rowIndex + span]
    if (columnIndex == 0 && row.custom != nextrow.custom) {
      break
    }
    if (columnIndex == 1 && (row.custom != nextrow.custom || row.storage != nextrow.storage)) {
      break
    }
  }
  return [span, 1]
}
</script>

<style lang="less"></style>